<template>
      <div class="s-flat-box">
    <div class="s-plr-16">
      <h2>Forgot your password ? </h2>
      <p>Please fill new password and remember it to login again.</p>
      <div>
        <Input
          v-model="password"
          class="s-mt-50"
          label="Password"
          placeholder="********"
          type="password"
        ></Input>
      </div>
      <div class="s-fullWidth s-mt-30">
            <Button @click.prevent="onFinish()" class="s-fullWidth">Update Password</Button>
      </div>
    </div>
    <hr class="s-line-primary s-mtb-16" />
    <div class="s-plr-16">
      <div class="s-fullWidth s-mt-30">
        <router-link
          :to="{
            name: 'authentication',
            params: { authType: 'create-account' },
          }"
        >
          <Button class="s-fullWidth" color="secondary">Create Account</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import form from '@/mixins/form';
import { mapActions } from "vuex";

export default {
    mixins: [form],
    data: () => ({
      password :'',
    }),

     methods: {
    ...mapActions({
      changePassword: "auth/changePassword",
    }),

    async onFinish(){
    const data = {
      id : this.$route.query.id,
      new_password: this.password
    }
    await this.changePassword(data); 
    this.$router.push(this.$translate({
      path: 'login',
    }));
    }
  }
}
</script>